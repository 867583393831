import { Dictionary } from '../context/Dictionary';
import { getCmsFieldLabel } from '../services/dictionaryService';
export type PolicyDictionaries =
	| 'MissingPolicies'
	| 'GetPolicyOffer'
	| 'InsuranceInformation'
	| 'NumberOfCoInsured'
	| 'NumberOfInsured'
	| 'DownloadPolicy';

export const _Policy = (
	dictionary: Partial<Dictionary>,
	item: PolicyDictionaries,
	overviewFields: Array<OverviewFields>,
): string => {
	if (!overviewFields) {
		return '';
	}

	switch (item) {
		case 'MissingPolicies':
			return dictionary.getValue('Policy.MissingPolicies', null, 'Du har ingen forsikringer.');

		case 'GetPolicyOffer':
			return dictionary.getValue('Policy.GetPolicyOffer', null, 'Indhent forsikringstilbud');

		case 'InsuranceInformation':
			return getCmsFieldLabel(overviewFields, 'InsuranceInformation') || 'Forsikringsoplysninger';

		case 'NumberOfCoInsured':
			return getCmsFieldLabel(overviewFields, 'NumberOfCoInsured') || 'Antal medforsikrede';

		case 'NumberOfInsured':
			return dictionary.getValue('Policy.NumberOfInsured', null, 'Antal forsikrede');

		case 'DownloadPolicy':
			return dictionary.getValue('Policy.DownloadPolicy', null, 'Hent forsikringspolice');
	}
};
