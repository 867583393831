export const getCmsFieldLabel = (dictionary: InfoField[], id: string): string => {
	let res: string;
	if (dictionary?.length > 0) {
		res = dictionary.find((x) => x.fieldId.toLowerCase() === id.toLowerCase())?.label;
	}
	return res?.length > 0 ? res : undefined;
};

export const getCmsFieldHelpText = (dictionary: InfoField[], id: string): string => {
	let res: string;
	if (dictionary?.length > 0) {
		res = dictionary.find((x) => x.fieldId.toLowerCase() === id.toLowerCase())?.helpText?.fragments[0]
			.internalFormat;
	}
	return res;
};

export const getCmsFieldPlaceholder = (dictionary: InfoField[], id: string): string => {
	let res: string;
	if (dictionary?.length > 0) {
		res = dictionary.find((x) => x.fieldId.toLowerCase() === id.toLowerCase())?.placeholder;
	}
	return res?.length > 0 ? res : undefined;
};
